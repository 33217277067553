import React from 'react';
import { connect } from 'react-redux';
import {datosCompra} from '../../../types/types';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';

type configObjectDatosComprados = {
    eventsReducer: {
        detalleCompra:datosCompra
    }
  }

const TituloEvento = ({detalleCompra}:{detalleCompra:datosCompra}) => {
    return (
        <>
            <div><p className="h4 titulo_evento_en_paso text_2" dangerouslySetInnerHTML={{ __html: detalleCompra.eventName }} /></div>
            <div className='d-flex justify-content-center time-date-wrapper'>
                <div><DateRangeIcon /></div>
                <div> {detalleCompra.eventDate}</div>
                <div><WatchLaterOutlinedIcon /></div>
                <div>{detalleCompra.eventHour}</div>
            </div>
        </>
    );
};

const mapStateToProps = (state: configObjectDatosComprados) => {
    return {
        detalleCompra: state.eventsReducer.detalleCompra
    }
}
export default connect(
    mapStateToProps,
)(TituloEvento);
