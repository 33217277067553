import { ReactNode, ReactElement } from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../../sass/evento_seleccionado/info_general.scss'

const InfoGeneral = (props: { duration: string, classification: string }) => {

    const RowGeneral = (props: { icon: ReactElement, title: string, text: string, children?: ReactNode, textBold?:boolean }) => {
        return (
            <Row className='info-general'>
                <Col className='d-flex align-items-center'>
                    {props.icon}
                    <div className='ms-2 align-items-center' style={{"flex":"1"}}>
                    {props.textBold && <>
                            <p><b>{props.text}</b></p>
                        </>}
                        {!props.textBold && <>
                            <p>{props.text}</p>
                        </>}
                    </div>
                </Col>
                {props.children}
            </Row>
        )
    }

    return (
        <>
            {props.duration !== "" 
                && <RowGeneral icon={<WatchLaterOutlinedIcon />} title='Duración' text={props.duration} >
                    <hr className='hr-info-2'/>
                </RowGeneral>
            }
            
            {props.classification !== "" 
                && <RowGeneral icon={<AccountCircleOutlinedIcon />} title='Clasificación' text={props.classification} />
            }
            <hr className='hr-info'/>
        </>
    )
};

export default InfoGeneral;