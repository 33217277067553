import { Client } from "./baseurl";
const path:string =  'help-text/type';

export const getAyuda = async(page:string) => {
    const response = await Client.get(`/${path}/${page}`);
    return response.data;
}

export const getTelefonoAyuda = async() => {
    const response = await Client.get(`/${path}/telefono-ayuda`);
    return response.data;
}

export const getTelefonoComercial = async() => {
    const response = await Client.get(`/${path}/telefono-comercial`);
    return response.data;
}

export const getEmailComercial = async() => {
    const response = await Client.get(`/${path}/email-comercial`);
    return response.data;
}