import React, { useState, useEffect, Dispatch } from 'react';
import { EventsData } from '../../types/types'
import { connect } from 'react-redux';
import Spinner from './Spinner';
import { useNavigate } from 'react-router-dom';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import { resetPasosCompra } from '../../reducer/events-reducer';
interface configObjectResultados {
    configReducer: {
        resultadosBusqueda: EventsData,
    }
}
interface propiedades {
    resultadosBusqueda: EventsData,
    resetPasosCompra: Dispatch<void>,
    buscandoResultados: boolean,
    setTextToSearch: (txt: string) => void
}

type Resultado = {
    id: string;
    title: string;
    category: string;
};

type LiResultadosProps = {
    tituloLi: string;
    resultados: Resultado[];
};

const ResultadosBusqueda = (props: propiedades) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if ((!props.buscandoResultados && !props.resultadosBusqueda) ||
        (props.resultadosBusqueda && props.resultadosBusqueda[0] &&  Object.values(props.resultadosBusqueda[0]).every(arr => Array.isArray(arr) && arr.length === 0))) {
            setDropdownOpen(false)
        } else {
            setDropdownOpen(true)
        }
    }, [props.buscandoResultados, props.resultadosBusqueda]);

    const handleMenuBusqueda = () => {
        setDropdownOpen((prevState) => !prevState);
    }

    const handleLinkResultado = (tipoEvento: string, id: string) => {
        props.setTextToSearch(id);
        switch (tipoEvento) {
            case 'Provincias':
                navigate(`/eventos?p=${id}`);
                break;
            case 'Recintos':
                navigate(`/eventos?pl=${id}`);
                break;
            case 'Eventos':
                props.resetPasosCompra()
                navigate(`/evento/${id}/step/1`);
                break;
            case 'Localidades':
                navigate(`/eventos?lo=${id}`);
                break;
            case 'Grupos':
                navigate(`/eventos?group=${id}`);
                break;
        }

    }

    const LiResultados = ({ tituloLi, resultados }: LiResultadosProps) => {
        if (resultados.length === 0) {
            return <></>
        }
        return (
            <li>
                <p className='text-muted text-uppercase'>{tituloLi}</p>
                <ul>
                    {resultados.map((resultado) => {
                        return <li key={resultado.id} onClick={() => { handleLinkResultado(tituloLi, resultado.id); handleMenuBusqueda() }}>{resultado.title}</li>;
                    })}
                </ul>
            </li>
        );
    };


    return (
        <div className='resultados-busqueda'>
            <Dropdown isOpen={dropdownOpen} toggle={handleMenuBusqueda}>
                <DropdownMenu>
                    <DropdownToggle tag="span"></DropdownToggle>
                    {props.buscandoResultados && <Spinner style={{ "top": "10%", "left": "32%", "transform": "scale(0.7)" }} />}
                    {props.resultadosBusqueda && !props.buscandoResultados && props.resultadosBusqueda.map((resultado, index) => {
                        return (<ul key={`resultado-${index}`}>
                            <LiResultados tituloLi="Eventos" resultados={resultado.events} />
                            <LiResultados tituloLi="Grupos" resultados={resultado.grupos} />
                            <LiResultados tituloLi="Recintos" resultados={resultado.recintos} />
                            <LiResultados tituloLi="Provincias" resultados={resultado.provincias} />
                            <LiResultados tituloLi="Localidades" resultados={resultado.localidades} />
                        </ul>)
                    })}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

const mapStateToProps = (state: configObjectResultados) => {
    return {
        resultadosBusqueda: state.configReducer.resultadosBusqueda,
    }
}
export default connect(
    mapStateToProps,
    {resetPasosCompra}
)(ResultadosBusqueda);
