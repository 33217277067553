import React, { useState } from 'react';
import { Row, Col} from 'reactstrap';
import { directPay } from '../../../api/purchase';
import TextField from '../TextField';
import CustomButton from '../../components/Button';

const TipoPagoDirectPaid = () => {
    const [dni, setDni] = useState('');
    const [error, setError] = useState('');

    const submitHandler = async () => {
        const res = await directPay(dni, "DESKTOP");
        if (res.id) {
            window.location.href = `https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=${res.id}`;
            //setDatosRecibidos(res);
            //setRedirectLink(`https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=${res.id}`)
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let str;
        var pattern = /^([0-9]+)?$/;
        if (pattern.test(event.target.value)) {
            str = event.target.value;
        } else {
            return;
        }
        setDni(str)
    }

    const validateInput = async (name: string, value: string, event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let error = '';

        if (event.target.required && event.target.value.trim() === '') {
            error = 'Complete el campo';
        } else if (event.target.value.length > parseInt(event.target.getAttribute('max') || '')) {
            error = `El campo debe tener como máximo ${event.target.getAttribute('max')} caracteres`;
        }

        setError(error)

        if (error !== '') {
            event.target.classList.add('has-error');
        } else {
            event.target.classList.remove('has-error');
        }
    };

    return (
        <div>
            
                <>
                    <Row>
                        <Col>
                            <h4 className='subtitles'>Pago con Dinero en Cuenta Mercado Pago</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextField required={true} type='text' id='dni' label='Ingresa tu DNI (sin puntos)' value={dni} errorMsg={error} handleInputChange={handleInputChange} validate={validateInput} focusEvent={() => { setError(''); setDni('') }} />
                        </Col>
                    </Row>
                    <Row>                                                                                                                                                  
                        <Col>
                            <p><strong>IMPORTANTE:</strong> con el botón que está más abajo te vamos a redireccionar a Mercado Pago. Luego de pagar, busca en la pantalla que te muestra Mercado Pago el botón "VOLVER AL SITIO", que aparece bien al final de dicha pantalla y hazle click. Si no haces esto NO se generaran las entradas.</p>
                        </Col>
                    </Row>
                    <Row className='mt-3 text-center'>
                        <Col className='steps-buttons text-center'>
                            <CustomButton className='mb-3 bt-pagar-tipo-pago' disabled={dni === ''} onClick={submitHandler}>Dinero en Cta. Mercado Pago</CustomButton>
                        </Col>
                    </Row>
                </>
            {/* {datosRecibidos &&
                <>
                    <Row>                                                                                                                                                  
                        <Col>
                            <p><strong>IMPORTANTE:</strong> con el botón que está más abajo te vamos a redireccionar a Mercado Pago. Luego de pagar, busca en la pantalla que te muestra Mercado Pago el botón "VOLVER AL SITIO", que aparece bien al final de dicha pantalla y hazle click. Si no haces esto NO se generaran las entradas.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='steps-buttons text-center mt-2'>
                            <Link to={redirectLink}><Button className='mb-3 bt-pagar-tipo-pago'>IR A PAGAR A MERCADO PAGO</Button></Link>
                        </Col>
                    </Row>
                </>
            } */}
        </div>
    );
};

export default TipoPagoDirectPaid;