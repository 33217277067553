import { useState } from 'react';
import {
    Row,
    Modal, Button, ModalHeader, ModalBody,
} from 'reactstrap';
import { mediaItem } from '../../../types/types';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from 'react-device-detect';
import '../../sass/evento_seleccionado/info_general.scss'

const EventoFotos = (props: { medialist: mediaItem[] }) => {

    const [modal, setModal] = useState<boolean>(false);
    const toggle = () => {
        setModal(!modal);
    }
    
    const handleVerImagenes = () => {
        setModal(true)
    }
    
    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true} size='lg' className='evento-seleccionado-modal fotos'>
                <ModalHeader toggle={toggle}>{<>FOTOS Y VIDEOS</>}</ModalHeader>
                <ModalBody>
                    <Carousel autoPlay showArrows={true} showThumbs={false} showStatus={false} dynamicHeight={true}>
                        {props.medialist.map((media, index) => {
                            return (
                                <div key={index}>
                                    {media.url_mobile.split('.').pop() == 'mp4' && 
                                    <>
                                        <video className='eev-video' autoPlay={true} controls src={media.url_large}></video>
                                    </>}
                                    {media.url_mobile.split('.').pop() != 'mp4' && 
                                    <>
                                    <img src={isMobile ? media.url_mobile : media.url_large} alt={media.title} />
                                    </>
                                    }
                                </div>
                            )
                        })}
                    </Carousel>
                </ModalBody>
            </Modal>
            {
                <Row className='info-general'>
                    <div className='d-flex justify-content-center'>
                        <div className='d-flex justify-content-around buttons-wrapper'>
                            <Button outline onClick={handleVerImagenes} className='btn-imagenes-videos'>Ver Fotos y Videos</Button>
                        </div>
                    </div>
                </Row>
            }
        </>
    )
};

export default EventoFotos;