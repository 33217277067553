import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PurchaseData, datosCompra, sectorList, eventoSeleccionado, MediaSeatWidget } from '../../../types/types';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { fetchDetalleCompra } from '../../../reducer/events-reducer';
import { getPrices, purchaseSell } from '../../../api/purchase';
import { Table } from 'antd';
import '../../sass/evento_seleccionado.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../components/Button'

export type configObjectEventos = {
    eventsReducer: {
        datosCompraPasoDos: PurchaseData;
        detalleCompra: datosCompra;
        eventoSeleccionado: eventoSeleccionado;
    };
};

const CompraEventoPlural = ({
    datosCompraPasoDos,
    fetchDetalleCompra,
    eventoSeleccionado,
    detalleCompra
}: {
    datosCompraPasoDos: PurchaseData;
    fetchDetalleCompra: () => any;
    eventoSeleccionado: eventoSeleccionado;
    detalleCompra: datosCompra
}) => {
    const [modal, setModal] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [selectedListItem, setSelectedListItem] = useState<sectorList | null>(null);
    const [selectedPriceIndex, setSelectedPriceIndex] = useState<number | null>(0);
    const [disableBt, setdisableBt] = useState(false);
    const [mediaSeatWidget, setMediaSeatWidget] = useState<MediaSeatWidget | null>(null);
    const [errorMessage, seterrorMessage] = useState();

    const columns = [
        {
            title: '',
            dataIndex: 'radio',
            key: 'radio',
            render: (_: any, record: any) => (

                <Input type="radio" checked={record.key === selectedPriceIndex} onChange={() => handlePriceSelection(record.key)} />
            ),
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
    ];

    const toggle = () => {
        setModal(!modal);
        if(modal) {
            setSelectedPriceIndex(0);
        }
    };

    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
    }

    const handlePriceSelection = (index: number) => {
        setSelectedPriceIndex(index);
        setdisableBt(false);
    };

    const handleSector = async (sector: sectorList) => {
        const response = await getPrices(sector.code);

        if(response.result === true){
            sector.prices = response.prices;
            toggle();
            setSelectedListItem(sector);
        }
        
    }

    const handleAceptarButton = async() => {

        if (selectedPriceIndex !== null) {
            setdisableBt(true)
            const res = await purchaseSell(selectedListItem!.code, selectedPriceIndex);
            if (res.result === true) {
                await fetchDetalleCompra();
                setSelectedPriceIndex(null);
                setdisableBt(false);
                toggle();
            } else {
                let message = res.message;
                if (message.includes('NO_ACEPTA_MAS_VENTAS')) {
                    message = "No hay más Lugares Disponibles";
                }

                seterrorMessage(message);
                toggle();
                toggleErrorModal();
            }
        }
    }

    useEffect(() => {
        fetchDetalleCompra();
    }, [fetchDetalleCompra]);

    useEffect(() => {
        if (detalleCompra) {
            setMediaSeatWidget(detalleCompra.mediaSeatWidget);
        }
    }, [detalleCompra])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dataSource = selectedListItem
        ? selectedListItem.prices.map((precio, index) => ({
            key: index,
            radio: <Input type="radio" name='radio' />,
            label: precio.label,
            price: precio.price,
        }))
        : [];

        useEffect(()=>{
            if (dataSource.length === 1) {
                handlePriceSelection(0);
              }
        },[dataSource])
    return (
        <>
            <Modal isOpen={errorModal} toggle={toggleErrorModal} centered={true}>
                <ModalHeader toggle={toggleErrorModal}>AVISO</ModalHeader>
                <ModalBody className='error-handler'>
                    <p className='text-center error-message'>{errorMessage}</p>
                    <div className='d-flext justify-content-center p-0 mb-2'>
                        <Row className='text-center'>
                            <Col md={12} className='pb-2 pt-2'>
                                <Button className='cancel-bt ps-5 pe-5' onClick={()=>{ toggleErrorModal(); }}>CERRAR </Button>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modal} toggle={toggle} centered={true} className='modal-compra-evento-plural' backdrop='static'>
                <ModalHeader toggle={toggle}>ESTAS AGREGANDO UN<br /> ITEM A TU COMPRA</ModalHeader>
                {selectedListItem && (
                    <>
                        <ModalBody className='pb-0'>
                                <Col>
                                    <h5 className="text-center"><strong>{selectedListItem.name}</strong></h5>
                                </Col>
                                <Col className='mt-3 mb-3'>
                                    <p className='text-center'>Selecciona Tipo de Precio:</p>
                                    <Table columns={columns} dataSource={dataSource} pagination={false} showHeader={false} />
                                </Col>
                            <div className='d-flext justify-content-center p-0 mb-2'>
                                <Row className='text-center'>
                                    <Col md={12} className='pb-2 pt-2'>
                                        <Button className='cancel-bt' onClick={()=>{ toggle(); setSelectedPriceIndex(null) }}>CANCELAR <CloseIcon /></Button>
                                        <CustomButton disabled={disableBt} onClick={handleAceptarButton} className='me-2 ms-2'>ACEPTAR <ArrowForwardIosIcon /></CustomButton>
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>

                    </>
                )}
            </Modal>
            <Col>
            <Row>
                {!mediaSeatWidget && eventoSeleccionado.image && <Col md={4} className='p-0'> <img src={eventoSeleccionado.image} alt='' className='img-fluid' /></Col>}
                {mediaSeatWidget && <Col> <img src={mediaSeatWidget.urlDesktop} alt='' className='img-fluid' /></Col>}
                <Col className='text-center' md={8}>
                <h4 className='text-center subtitle'>Agrega Tus Lugares</h4>
                <p className='subtitle-paragraph'>Toca el Sector y repite tantas veces como lugares a agregar</p>
                <div className='button-wrapper-tipo-plural'>{datosCompraPasoDos.sectorList.map((sector, index) => {
                    if (sector.enable) {
                    return (
                        <Button className='compra-plural-bt mb-2' key={index} onClick={() => {
                            handleSector(sector)
                        }}>
                            {sector.name}
                        </Button>
                    );
                    }
                })}</div></Col>
                </Row>
            </Col>
        </>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        datosCompraPasoDos: state.eventsReducer.datosCompraPasoDos,
        detalleCompra: state.eventsReducer.detalleCompra,
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
    };
};

export default connect(
    mapStateToProps,
    { fetchDetalleCompra }
)(CompraEventoPlural);