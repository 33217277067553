import React, { useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Container
} from 'reactstrap';
import { connect } from 'react-redux';
import { eventoSeleccionado } from '../../../types/types';
import '../../sass/timeout.scss';

type configObjectEventos = {
    eventsReducer: {
        eventoSeleccionado: eventoSeleccionado;
     }
}

interface propiedades {
    eventoSeleccionado: eventoSeleccionado;
    setTimeout:React.Dispatch<React.SetStateAction<boolean>>
   }
const Timeout = (props:propiedades) => {

    useEffect(()=>{
        localStorage.removeItem('token');
    },[])

    const goBackHandler = () => {
        window.location.href = `${window.location.origin}/evento/${props.eventoSeleccionado.eventHash}/step/1`;
    }

    return (<Container className='text-center time-out'>
        <Row>
            <Col xs={{ size: 4, offset: 4 }} className='mt-4'>
                <img src="/images/icono-tiempo-agotado.svg" alt="icono de busqueda sin resultados" className='img-fluid' />
            </Col>
        </Row>
        <Row>
            <Col>
                <h1 className='title text_3 pt-3'>
                    Se agotó el tiempo disponible para tu Compra!
                </h1>
            </Col>
        </Row>
        <Row>
            <Col className='text-start ps-2 pe-1 pt-2 col' style={{ "lineHeight": "22px" }}>
                <p>
                    Lo sentimos, por razones de seguridad  solo podemos darte 25 minutos para completar el proceso de compra. Te pedimos que vuelvas al inicio y comiences de nuevo.
                </p>
            </Col>
        </Row>
        <Row>
            <Col className='mb-4'><Button className='back_3 border-0 mt-2 fw-bold' onClick={goBackHandler}> VOLVER</Button></Col>
        </Row>
    </Container>)
}

const mapStateToProps = (state: configObjectEventos) => {
    return {
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
    }
}

export default connect(
    mapStateToProps,
   null
)(Timeout);

