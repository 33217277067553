import React from 'react';
import {evento} from '../../../types/types';
import { isMobile } from "react-device-detect";

interface propiedades {
    evento: evento;
    handleEvent:(itemTypeGroup:string) => void;
  }
const EventoImagen = (props:propiedades) => {
    const {evento, handleEvent} = props;
    return (
            <>
                <img src={ isMobile ? evento.mobileImage : evento.image} alt={evento.title.replace(/<[^>]+>/g, '')}
                className='w-100'
                onClick={()=>{handleEvent(evento.eventHash)}}  />
                {evento.status === '0' &&
                    <div className='position-absolute start-0 p-2 w-100' style={{"top":"50%", "marginTop":"-3em"}} onClick={()=>{handleEvent(evento.eventHash)}}>
                        <img src="/images/agotado.png" alt="agotado" className='w-100' />
                    </div>
                }
            </>
    );
};

export default EventoImagen;