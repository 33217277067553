import { Client } from "./baseurl";
import * as dataAr from '../lang/ar.json';
import * as dataEs from '../lang/es.json';

export const getProvinciasCompra = async() => {
  const response = await Client.get('/provinces');
  return response.data;
}

export const getLocalities = async(provinceId:string) => {
    const response = await Client.get(`/localities/province/${provinceId}`);
    return response.data;
  }


export const getLangValue = (type:string) => {

    let url = process.env.REACT_APP_BACKEND_URL;
    let lang = "es_AR";
    let langValue= "";

    if (url !== undefined && (url?.indexOf("https://bff.entradaweb.es/v1") >= 0)) {
      lang = "es_ES";
    }

    if(process.env.REACT_APP_LANG_FORCE){
      lang = process.env.REACT_APP_LANG_FORCE;
    }

    switch (type) {
      case "s3":
        langValue = "https://imgs.entradaweb.com.ar";
        if (lang === "es_ES") {
          langValue = "https://imgs.entradaweb.es";
        }
        break;
      case "front":
        langValue = "https://www.entradaweb.com.ar/";
        if (lang === "es_ES") {
          langValue = "https://www.entradaweb.es";
        }
        break;
      case "mi":
        langValue = "https://boleteria.entradaweb.com.ar/";
        if (lang === "es_ES") {
          langValue = "https://mi.entradaweb.es/";
        }
        break;
      case "recaptcha_private_key":
          langValue = "6LdGL54pAAAAAF6isi66CBbymjqh1YsYP9ivPKFU";
          if (lang === "es_ES") {
            langValue = "6LcHjfYpAAAAADowjOWuA3t2hvES4zFULKn9Eylw";
          }
          break;
      case "lang":
        langValue = lang;
        break;
      case "about-us-img-ceo":
        langValue = "/productores/miguel.jpeg";
        if (lang === "es_ES") {
          langValue = "/productores/ALEJANDRO.jpg";
        }
        break;
      case "about-us-img-contable":
        langValue = "/productores/vero.jpg";
        if (lang === "es_ES") {
          langValue = "/productores/VERONICA.jpg";
        }
        break;
      case "about-us-img-infra":
        langValue = "/productores/marcelorocca.jpeg";
        if (lang === "es_ES") {
          langValue = "/productores/MARCELO.jpg";
        }
        break;
      case "about-us-img-help-desk":
        langValue = "/productores/rocio.jpg";
        break;
      case "about-us-img-help-buyers":
        langValue = "/productores/yasmina.jpg";
        break;
      case "about-us-img-access-control":
        langValue = "/productores/ornella.jpg";
        break;
      case "facebook_url":
          langValue = "http://www.facebook.com/entradaWeb";
          if (lang === "es_ES") {
            langValue = "https://www.facebook.com/Entradaweb.es/";
          }
          break;
      case "twitter_url":
          langValue = "http://www.twitter.com/entradaWeb";
          if (lang === "es_ES") {
            langValue = "https://twitter.com/entradaweb_es";
          }
          break;
      case "instagran_url":
          langValue = "https://www.instagram.com/entradaWeb";
          if (lang === "es_ES") {
            langValue = "https://www.instagram.com/entradaweb.es";
          }
          break;
      case "product-salas-title":
        langValue = "Vendé y comprá entradas online para tu próximo evento en Entradaweb Argentina";
          if (lang === "es_ES") {
            langValue = "Vende y compra entradas online para tu próximo evento en Entradaweb España";
          }
          break;
      default:
        langValue = "https://www.entradaweb.com.ar/";
        break;
    }

    return langValue;
}

export const setOgValues = (
    langValue: string, 
    ogType: string, 
    og_locale: string | null,
    og_description: string | null,
    og_url: string | null,
    og_image: string | null,
    og_title: string | null) => {
  let fileLang = dataAr;
  if (langValue === "es_ES") {
    fileLang = dataEs;
  }
  
  let ogLocale = document.querySelector('[property="og:locale"]') as HTMLMetaElement;
  ogLocale!.content = og_locale ? og_locale : fileLang.og_locale;

  let ogDesciption = document.querySelector('[property="og:description"]') as HTMLMetaElement;
  ogDesciption!.content = og_description ? og_description : fileLang.og_description;

  let ogUrl = document.querySelector('[property="og:url"]') as HTMLMetaElement;
  ogUrl!.content = og_url ? og_url : fileLang.og_url;

  let ogTypeDocument = document.querySelector('[property="og:type"]') as HTMLMetaElement;
  ogTypeDocument!.content = ogType;

  let ogImage = document.querySelector('[property="og:image"]') as HTMLMetaElement;
  ogImage!.content = og_image ? og_image : fileLang.og_image;

  let ogTitle = document.querySelector('[property="og:title"]') as HTMLMetaElement;
  ogTitle!.content = og_title ? og_title : 'Entradaweb';

}

export const setHeadersValues = (langValue:string) => {

  
  let fileLang = dataAr;
  let headersValues = null;
  
  if (langValue === "es_ES") {
    fileLang = dataEs;
  }
  
  try {
    document.title = fileLang.page_title;

    setOgValues(langValue, "website", null, null, null, null, null);

    let metaTitleX = document.querySelector('meta[name="twitter:title"]') as HTMLMetaElement;
    metaTitleX!.content = fileLang.twitter_title;

    let metaDescriptionX = document.querySelector('meta[name="twitter:description"]') as HTMLMetaElement;
    metaDescriptionX!.content = fileLang.twitter_title;

    let metaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    metaDescription!.content = fileLang.page_meta_description;

    let metakey = document.querySelector('meta[name="keywords"]') as HTMLMetaElement;
    metakey!.content = fileLang.page_meta_keywords;

    let googleSiteKey = document.querySelector('meta[name="google-site-verification"]') as HTMLMetaElement;
    googleSiteKey!.content = fileLang.google_site_verification;

    let paragraph1 = document.getElementById("hidden-paragraph_1") as HTMLParagraphElement;
    paragraph1.innerHTML = fileLang.page_hidden_paragraph_1;

    if (fileLang.page_hidden_paragraph_1_3 !== "") {
      paragraph1.insertAdjacentHTML('afterend', fileLang.page_hidden_paragraph_1_3);
    }

    if (fileLang.page_hidden_paragraph_1_2 !== "") {
      paragraph1.insertAdjacentHTML('afterend', fileLang.page_hidden_paragraph_1_2);
    }
    
    if (fileLang.page_hidden_paragraph_1_1 !== "") {
      paragraph1.insertAdjacentHTML('afterend', fileLang.page_hidden_paragraph_1_1);
    }

    let paragraph2 = document.getElementById("hidden-paragraph_2") as HTMLParagraphElement;
    paragraph2.innerHTML = fileLang.page_hidden_paragraph_2;
    let paragraph3 = document.getElementById("hidden-paragraph_3") as HTMLParagraphElement;
    paragraph3.innerHTML = fileLang.page_hidden_paragraph_3;

    if (fileLang.page_hidden_paragraph_3_2 !== "") {
      paragraph3.insertAdjacentHTML('afterend', fileLang.page_hidden_paragraph_3_2);
    }

    if (fileLang.page_hidden_paragraph_3_1 !== "") {
      paragraph3.insertAdjacentHTML('afterend', fileLang.page_hidden_paragraph_3_1);
    }

    if (fileLang.script_seo !== "") {
      document.head.insertAdjacentHTML('beforeend', fileLang.script_seo);
    }

  } catch (error:any) {
    console.error('Error al parsear el JSON:', error.message);
  }


  return headersValues;
}