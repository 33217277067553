import React, { useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Container
} from 'reactstrap';
import { connect } from 'react-redux';
import { eventoSeleccionado } from '../../../types/types';
import '../../sass/timeout.scss';

type configObjectEventos = {
    eventsReducer: {
        eventoSeleccionado: eventoSeleccionado;
    }
}

interface propiedades {
    eventoSeleccionado: eventoSeleccionado;
    setTimeout: React.Dispatch<React.SetStateAction<boolean>>
}
const Timeout = (props: propiedades) => {
    
    useEffect(() => {
        localStorage.removeItem('token');
    }, [])

    const goBackHandler = () => {
        window.location.href = `${window.location.origin}/evento/${props.eventoSeleccionado.eventHash}/step/1`;
    }

    return (<Container className='text-center time-out d-flex align-items-center'>
        <div>
        <Row>
            <Col md={{ size: 8, offset: 2 }}>
                <Row>
                    <Col md={3} className='mt-4 d-flex justify-content-center'>
                        <img src="/images/icono-tiempo-agotado.svg" alt="icono de busqueda sin resultados" className='img-fluid' />
                    </Col>
                    <Col md={9} className='d-flex justify-content-center flex-column text-start'>
                        <h1 className='title text_3 pt-3'>
                            ¡Se agotó el tiempo!
                        </h1>
                        <p>
                            Lo sentimos, por razones de seguridad  solo podemos darte 25 minutos para completar el proceso de compra. Te pedimos que vuelvas al inicio y comiences de nuevo.
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col md={{ size: 8, offset: 2 }} className='d-flex align-items-center justify-content-center'>
                <Button className='back_3 border-0 mt-2 fw-bold ps-4 pe-4' onClick={goBackHandler}>
                    VOLVER
                </Button>
            </Col>
        </Row>
        </div>
    </Container>)
}

const mapStateToProps = (state: configObjectEventos) => {
    return {
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
    }
}

export default connect(
    mapStateToProps,
    null
)(Timeout);

