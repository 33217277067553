// Componente Button
import React, { useState, useEffect } from 'react';
import { Button as RSButton } from 'reactstrap';
import Spinner from './Spinner';

interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
  reloadButton?: boolean; // Agregado el prop para cambiar onloading a false
}

const Button: React.FC<ButtonProps> = ({ onClick, disabled, className, children, reloadButton }) => {
  const [onloading, setOnLoading] = useState(false);
  const mostrarPreloader = false;

  const handleClick = async () => {
    setOnLoading(true);
    await onClick();
    setOnLoading(false);
  };

  useEffect(()=>{
    if(reloadButton){
      setOnLoading(false);
    }
  },[reloadButton])

  return (
    <RSButton
      onClick={handleClick}
      disabled={disabled || onloading}
      className={className}
    >
      {(onloading && mostrarPreloader) ? <Spinner style={{'position':'relative', 'transform':'scale(0.5) translate(0, -35px)', 'height':'10px'}} /> : children}
    </RSButton>
  );
};

export default Button;