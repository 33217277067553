import React,{useEffect, useState} from 'react';
import '../../sass/barra_superior.scss';
import { eventoSeleccionado } from '../../../types/types';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BoyOutlinedIcon from '@mui/icons-material/BoyOutlined';
import {Row,Col, Modal, ModalBody, ModalHeader} from 'reactstrap';
import { connect } from 'react-redux';
import Button from '../../components/Button'

type propiedades = {
    eventoSeleccionado: eventoSeleccionado
}

const BarraSuperior = (props: propiedades) => {
    const { eventoSeleccionado } = props;

    const [modal, setModal] = useState<boolean>(false);
    const [reloadButton, setReloadButton] = useState(false);

    const toggle = () => {
        setModal(!modal);
    }

    useEffect(()=>{
        if(!modal){
            setReloadButton(true);
            setTimeout(()=>{setReloadButton(false)},1000)
        }

    },[modal])

    return (<>
        <Modal isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>PRECIOS</ModalHeader>
            {eventoSeleccionado.infoPrices && <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: eventoSeleccionado.infoPrices.replace(/\n/g, '<br />') }} />
            </ModalBody>}
        </Modal>
        <Row className='d-flex barra_superior_evento pt-2 pb-2 align-tems-center'>
            <Col md={4} className='d-flex align-items-center justify-content-center pe-1'><LocationOnOutlinedIcon /> {eventoSeleccionado.place}<br /> {eventoSeleccionado.location ? `${eventoSeleccionado.location}, ${eventoSeleccionado.country}` : ''}</Col>
            <Col md={2} className='text-center border-start d-flex align-items-center justify-content-center'><WatchLaterOutlinedIcon /> {eventoSeleccionado.duration}</Col>
            <Col md={2} className='text-center border-start d-flex align-items-center justify-content-center'><AccountCircleOutlinedIcon /> {eventoSeleccionado.classification}</Col>
            <Col md={2} className='text-center border-start d-flex align-items-center justify-content-center text-capitalize'><BoyOutlinedIcon /> {eventoSeleccionado.publicPresentationPlace}</Col>
            <Col md={2} className='text-end  border-start d-flex align-items-center justify-content-center'><Button onClick={toggle} reloadButton={reloadButton}>Ver Precios</Button></Col>
        </Row>
    </>

    );
};

const mapStateToProps = (state: any) => {
    return {
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
    }
}

export default connect(
    mapStateToProps,
)(BarraSuperior);
