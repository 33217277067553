import React, { useEffect } from 'react';
import { mercadoPagoValidateToken, redSysValidatePayment } from '../../api/purchase';
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';

const CompraRevalidarRedSys = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const dsSignatureVersion = urlParams.get('Ds_SignatureVersion');
        const dsMerchantParameters = urlParams.get('Ds_MerchantParameters');
        const dsSignature = urlParams.get('Ds_Signature');
        (async () => {
            if (dsMerchantParameters && dsSignature && dsSignatureVersion) {
                const res = await redSysValidatePayment(dsMerchantParameters, dsSignature, dsSignatureVersion);
                if (res.result) {
                    navigate(`/pago-finalizado/debitCreditCard/${res.checkoutData.code}`)
                }
            }
        })()
    }, [navigate])

    return (
        <div className='vh-100'>
            <h1 className='mt-5 text_3 general-title text-center'><b>Estamos procesando</b></h1>
            <h1 className='text_3 general-title text-center'><b>tu pago...</b></h1>
            <h1 className='text_3 general-title text-center'><b>Aguarda unos segundos...</b></h1>
            <Spinner className='text-center' style={{ "top": "50%", "left": "50%" }} />
        </div>
    );
};

export default CompraRevalidarRedSys;