import { useEffect, useState } from "react"
import { getLandingPageBySlug } from "../../api/home";
import { Button, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";


const LandingRecinto = () => {

    const [backgroundImage, setBackgroundImage] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [isResponse, setIsResponse] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            const currentUrl = window.location.href;
            console.log(currentUrl);
            const urlObj = new URL(currentUrl);
            const pathname = urlObj.pathname;
            const slug = pathname.split('/').pop();
            if(slug){
                const response = await getLandingPageBySlug(slug);
                if(response.bgMobileImage === ""){
                    window.location.href = response.url;
                } else {
                    setBackgroundImage(response.bgMobileImage);
                    setUrl(response.url);
                    setIsResponse(true);
                }
            }
        })()
    }, [])

    const goBackHandler = () => {
        window.location.href = window.location.origin;
    }

    const NoHayResultados = () => {
        return (<Container className='text-center pb-4'>
            <Row>
                <Col xs={{ size: 4, offset: 4 }} className='mt-4'>
                    <img src="/images/icono-busqueda-01.svg" alt="icono de busqueda sin resultados" className='img-fluid' />
                </Col>
            </Row>
            <Row>
                <Col><h1 className='text_3 fs-22 pt-3'>¡No encontramos ningún resultado!</h1></Col>
            </Row>
            <Row>
                <Col className='text-start ps-4 pe-4 fs-12 ps-5 pt-2' style={{ "lineHeight": "22px" }}>
                    <p><FormattedMessage id="event_not_found_text" /></p>
                </Col>
            </Row>
            <Row>
                <Col><Button className='btn-back back_3 border-0 ps-4 pe-4 mt-2 fw-bold' onClick={goBackHandler}> VOLVER</Button></Col>
            </Row>
        </Container>)
    }

    return (
        <>
        {isResponse && <>
            {url && <>
                <div className="background-image-landing" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
                {backgroundImage && <Link to={url}><Button className="floating-button-landing" color="primary">Ver Cartelera</Button></Link>}
                </>} 
                {!url && <NoHayResultados/>}
            </>}
        </>
    );
}

export default LandingRecinto;