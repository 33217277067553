import React, { CSSProperties } from 'react';
import '../sass/spinner.scss'

interface SpinnerProps {
    style?: CSSProperties;
    className?: string;
  }

const Spinner = ({ style, className }: SpinnerProps) => {
    const containerClassName = `lds-spinner ${className || ''}`;

    return (
        <div className={containerClassName} style={style}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    );
};

export default Spinner;