import React, {Dispatch} from 'react';
import { useState } from 'react';
import { session } from '../../../types/types';
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';

const SeleccionarSoloFecha = ({ sesiones, status, horaSeleccionada, setHoraSeleccionada }: { sesiones: session[], status: string, horaSeleccionada:string, setHoraSeleccionada:Dispatch<string>  }) => {
    const [dropdownOpenFecha, setDropdownOpenFecha] = useState(false);
    const [opcionSeleccionada, setOpcionSeleccionada] = useState('Selecciona Fecha')

    const handleDropDownFecha = () => {
        setDropdownOpenFecha((prevState) => !prevState);
    }

    const handleFecha = (title: string, index: number, code: string, status: string) => {
        if (status !== "0") {
            setOpcionSeleccionada(title);
            handleDropDownFecha()
            setHoraSeleccionada(code);
        }
    }

    return (
        <Row>
            <Col>
                <Dropdown isOpen={dropdownOpenFecha} toggle={handleDropDownFecha} direction='down'>
                    <DropdownToggle caret tag='div' className='select'>
                        <span>{opcionSeleccionada}</span>
                    </DropdownToggle>
                    <DropdownMenu tag={'ul'} className='options'>
                        {sesiones.length > 1 && sesiones.map((sesion, index) => {
                            return <li className={sesion.hours[0].status === "0" ? 'disable-date' : ""} key={index} onClick={() => { handleFecha(sesion.title+ " - " + sesion.hours[0].hour, index, sesion.hours[0].code, sesion.hours[0].status); }}>{sesion.title + " - " + sesion.hours[0].hour} {sesion.hours[0].status === "0" ? '(AGOTADO)' : null}</li>;
                        })}
                        {sesiones.length === 1 && sesiones[0].hours.map((hour, index) => {
                            return <li className={hour.status === "0" ? 'disable-date' : ""} key={index} onClick={() => { handleFecha(sesiones[0].title+ " - " + hour.hour, index, hour.code, hour.status); }}>{sesiones[0].title + " - " + hour.hour} {hour.status === "0" ? '(AGOTADO)' : null}</li>;
                        })}
                    </DropdownMenu>
                </Dropdown>
            </Col>
        </Row>
    );
};
export default SeleccionarSoloFecha;