import React from 'react';
import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const FueraDeVenta = () => {
    const navigate = useNavigate();

    const volverAlInicioHandler = () => {
        navigate(`/`)
    }

    return (
        <Container className='text-center pb-4 without-results'>
            <div className='mt-5'></div>
            <Row>
                <Col xs={{ size: 4, offset: 4 }} className='mt-4'>
                    <img src="/images/icono-busqueda-01.svg" alt="icono de fuera de venta" className='img-fluid icon-without-result' />
                </Col>
            </Row>
            <Row>
                <Col><h1 className='text_3 fs-22 pt-3'>¡Evento fuera de Venta!</h1></Col>
            </Row>
            <Row>
                <Col className='mt-5'><Button className='back_3' onClick={volverAlInicioHandler}> IR AL INICIO</Button></Col>
            </Row>
        </Container>
    );
};

export default FueraDeVenta;