import React from 'react';
import {evento} from '../../../types/types';
import { isMobile } from "react-device-detect";
import {Row,Col} from 'reactstrap';

interface propiedades {
    evento: evento;
    handleEvent:(itemTypeGroup:string) => void;
  }
const EventoImagen = (props:propiedades) => {
    const {evento, handleEvent} = props;
    return (
        <Row>
            <Col className='p-2 position-relative'>
                <img src={ isMobile ? evento.mobileImage : evento.image} alt={evento.title.replace(/<[^>]+>/g, '')}
                className='img-fluid'
                onClick={()=>{handleEvent(evento.eventHash)}}  />
                {evento.status === '0' &&
                    <div className='position-absolute start-0 p-3' style={{"top":"50%", "marginTop":"-3em"}} onClick={()=>{handleEvent(evento.eventHash)}}>
                        <img src="/images/agotado.png" alt="agotado" className='img-fluid' />
                    </div>
                }
            </Col>
        </Row>
    );
};

export default EventoImagen;