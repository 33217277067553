import Redsys from './Redsys';
import { parseJWt } from '../../../utilities/utilities';
import { Col, Row } from 'reactstrap';

const TipoPagoRedsys = () => {

    return (
        <>
            <Row>                                                                                                                                                  
                <Col>
                    <Redsys id='card-form' fuc='361814825' terminal='001' merchantOrder={parseJWt(localStorage.getItem('token')!).purchase_progress_code.split('-')[1]} insiteStyle='twoRows' buttonValue='Pagar' langInsite='ES' showLogo={"false"}/> 
                </Col>
            </Row>
        </>
    );
};

export default TipoPagoRedsys;